@import 'abstracts';

body .p-dialog.p-confirm-dialog {
  border-radius: 5px;
  background-color: $white;
  padding: 25px;
  min-width: 425px;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    min-width: 0;
    max-width: 95%;
  }

  .p-dialog-titlebar,
  .p-dialog-content,
  .p-dialog-footer {
    border: none;
    border-radius: inherit;
    color: $gray-dark;
    padding: 1em;

    p-footer {
      @include flexBetween();

      /* Yes/No buttons tend to be kind of small */
      .cap-button {
        min-width: 6em;
      }
    }
  }

  .p-dialog-header {
    margin: -25px -25px 0;
    padding: 25px 25px 20px;
    background-color: var(--cap-primary-color);
    color: $white;

    .p-dialog-title {
      font-size: 22px;
      font-weight: 600;

      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
    }

    .p-dialog-header-icons {
      button {
        color: $white;
      }
    }
  }

  .p-dialog-titlebar {
    @include straightBottom();
    font-family: $font-semi-bold;

    .p-dialog-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .p-dialog-content {
    font-size: 18px;
    color: $body-color;
    border-radius: 0;
    margin-bottom: 5px;
    padding: 25px 0;
    border-bottom: 1px solid $gray-light-secondary;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }

    .p-confirm-dialog-message {
      width: 100%;
      color: var(--primary-text);
    }
  }

  .p-dialog-footer {
    padding: 25px 0 0;
  }
}

div.p-overlaypanel {
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 50%);
  border: none;

  .p-overlaypanel-content {
    padding: 0;
  }
}
