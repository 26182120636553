@import 'abstracts';

* {
  outline: none;
}

body ::placeholder {
  color: rgba($body-color, 0.5);

  @include media-breakpoint-down(lg) {
    font-size: 14px;
  }
}

.page {
  &__top {
    margin-top: 20px;
    padding-bottom: 20px;

    @include media-breakpoint-down(sm) {
      border-bottom: 0;
    }
  }

  &__heading {
    font-size: 20px;
    font-weight: 900;
    color: $gray-dark;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 28px;
    }
  }

  &__actions {
    text-align: right;
    @include setRightGutters(20px);

    &--no-margin {
      margin: 0;
    }
  }

  &__card {
    @include card-layout(20px);
  }

  &__content {

    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }

  &__subheading {
    font-size: 14px;
    font-weight: 600;
    color: rgba($gray-dark, 0.5);
    border-bottom: 1px solid $cap-gray;
    padding: 12px 20px;
    margin-bottom: 10px;
  }

  &__header {
    cap-button {
      @include media-breakpoint-down(lg) {
        font-size: 13px;
      }
    }
  }
}

body .main__headline {
  .headline {
    &__title {
      font-size: 20px;
      font-weight: 900;
      color: $gray-dark;

      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 28px;
      }
    }
  }
}
