@import 'abstracts';

body cap-login {
  .login-wrapper {
    top: 50%;
    transform: translate3d(0, -50%, 0);

    @include media-breakpoint-down(sm) {
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }

    .content-wrapper {
      @include media-breakpoint-up(md) {
        margin-top: 0;
        min-height: 480px;
      }

      @include media-breakpoint-between(md, lg) {
        max-width: 90%;
      }

      .content-left {
        @include media-breakpoint-down(sm) {
          max-height: 83px;
        }

        .logo {
          @include media-breakpoint-up(md) {
            max-width: 182px;
            height: 105px;
          }
        }
      }

      .content-right {
        @include media-breakpoint-up(md) {
          height: auto;
        }

        cap-validator {
          .validator {
            @include media-breakpoint-down(sm) {
              min-height: 14px;
            }

            &.show {
              .error-text {
                @include media-breakpoint-down(sm) {
                  padding-bottom: 6px;
                  display: inline-block;
                }
              }
            }
          }
        }

        .login-form {
          .title {
            padding-bottom: 21px;

            .text {
              font-size: 28px;

              @include media-breakpoint-down(sm) {
                font-size: 20px;
              }
            }
          }

          .login-actions {
            padding-top: 20px;

            @include media-breakpoint-down(sm) {
              flex-direction: column;
              padding-top: 0;
            }

            cap-button {
              @include media-breakpoint-up(md) {
                font-size: 18px;
              }

              &[data-test='login-button'] {
                @include media-breakpoint-down(sm) {
                  width: 100%;
                }

                .cap-button {
                  @include media-breakpoint-down(sm) {
                    width: 100%;
                    font-size: 13px;
                  }
                }
              }
            }

            .login-separator {
              color: $cap-gray;

              @include media-breakpoint-down(sm) {
                display: none;
              }
            }

            .login-link {
              color: var(--cap-primary-color);
              font-size: 15px;
              font-weight: 600;

              @include media-breakpoint-down(sm) {
                padding-top: 20px;
                font-size: 13px;
                height: auto;
                display: inline-block;
              }
            }
          }
        }

        cap-input-switch {
          &[data-test='remember-switch'] {
            @include media-breakpoint-down(sm) {
              display: none;
            }
          }

          .form-element {
            display: flex;

            @include media-breakpoint-down(sm) {
              padding-top: 0;
            }

            & > label {
              padding-left: 10px;
            }
          }
        }

        cap-password {
          .cap-password {
            cap-validator {
              display: none;
            }
          }

          & > div {
            & > cap-validator {
              .validator {
                position: unset;
              }
            }
          }

          .display-password-toggle {
            top: 0;

            @include media-breakpoint-up(md) {
              display: none;
            }
          }
        }

        input {
          &::placeholder {
            color: rgba($body-color, 1);
          }
        }
      }
    }
  }
}
