@import 'abstracts';

@font-face {
  font-family: $font-regular;
  src: url('~/assets/fonts/opensans/OpenSans-SemiBold.woff2') format('woff2'), url('~/assets/fonts/opensans/OpenSans-SemiBold.woff') format('woff'), url('~/assets/fonts/opensans/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-semi-bold;
  src: url('~/assets/fonts/opensans/OpenSans-Regular.woff2') format('woff2'), url('~/assets/fonts/opensans/OpenSans-Regular.woff') format('woff'), url('~/assets/fonts/opensans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-bold;
  src: url('~/assets/fonts/opensans/OpenSans-Bold.woff2') format('woff2'), url('~/assets/fonts/opensans/OpenSans-Bold.woff') format('woff'), url('~/assets/fonts/opensans/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
