@import 'abstracts';

p-dynamicdialog {
  .p-dialog {
    min-width: 600px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      min-width: auto;
    }

    .p-dialog-header {
      padding: 34px;
      background: $white;

      .p-dialog-title {
        font-size: 24px;
        font-weight: bold;
      }
    }

    .p-dialog-content {
      background: $gray-light;
    }

    .p-dialog-header-icons {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
}
