@import 'abstracts';

p-dialog {
  div.p-dialog-header {
    background: white;
    padding: 20px;
    justify-content: flex-end;
    flex-wrap: wrap;

    .p-dialog-title {
      margin: 16px 0;
      display: block;
      width: 100%;
      order: 1;
      font-family: $font-bold;
      font-size: 24px;
      text-align: center;
    }

    .p-dialog-header-close-icon {
      color: $gray-dark;
    }
  }

  .p-component {
    max-width: 800px;
    width: 100%;

    .p-dialog-content {
      background-color: $gray-light;
    }
  }

  .dynamic-dialog {
    background-color: $white;

    &-mask {
      background-color: rgba($white, 0.75);
    }

    div.p-dialog-header {
      background-color: var(--cap-primary-color);
      color: $white;

      .title {
        flex-grow: 1;
        padding-right: 1rem;
        font-size: toRem(22);
      }

      .p-dialog-header-close-icon {
        color: $white;
      }
    }

    .p-dialog-content {
      border: 0;
      background-color: $white;

      @include media-breakpoint-up(xl) {
        padding: toRem(20) toRem(27);
      }
    }

    .p-dialog-footer {
      border: 0;
      border-top: 1px solid $gray-light-secondary;

      @include media-breakpoint-up(xl) {
        padding: toRem(27) 0;
        margin: 0 toRem(27);
      }

      .submit {
        margin: 0;
      }
    }
  }
}

.dynamic-dialog-container {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
