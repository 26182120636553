@import 'abstracts';
@import 'styles';

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
body .p-inputswitch.p-inputswitch-checked:not(.p-state-disabled):hover .p-inputswitch-slider,
body .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider {
  background: $green;
}

body .page-wrapper cap-button .p-button.cap-button {
  @include media-breakpoint-down(sm) {
    font-size: 13px;
  }
}

body .filter-icon i {
  opacity: 1;
}

body .ql-container {
  font-size: 1rem;
  font-family: var(--primary-font);
}
