/* General colors */
$primary: #005caa;
$primary-light: #f7fcfe;
$liver: #4f5152;

/* Fonts */
$font-light: opensans light;
$font-regular: opensans regular;
$font-semi-bold: opensans semibold;
$font-bold: opensans bold;

/* Spacing */
$mobile-spacing: 15px;
$dashboard-page-padding: 40px;

/* General */
$body-color: #4c4c4c;
$white: #fff;
$munsell: #f0f0f0;
$root-font-size: 16;

$cap-input-height: 33px;

/* General sizes */
$nav-height: 91px;
$nav-height-mobile: 72px;

/* General */
$gray-light: #f9f9f9;
$gray-light-secondary: #ddd;
$gray-light-tertiary: #adadad;
$gray-dark: #3c3c3b;
$gray-dark-secondary: #3a3a3a;
$gray-dark-tertiary: #5f6062;
$white: #fff;
$platinum: #e6e6e6;
$isabelline: #eee;

$cap-input-height: 33px;
