/* Base */
@import './base/reset';
@import './base/alignment';
@import './base/fonts/opensans';

/* Vendor */
@import './vendor/bootstrap';
@import './vendor/prime';
@import './vendor/capturum-ui';
@import './vendor/capturum-login';
@import './vendor/font-awesone';

/* Components */
@import 'components/buttons';
@import 'components/confirm-dialog';
@import 'components/dynamic-dialogs';
@import 'components/dialog';
@import 'components/dialog-component';
@import 'components/radio-buttons';
@import 'components/info-table';
@import 'components/list-renderer';
@import 'components/tooltips';
@import 'components/checkbox';

/* Layouts */
@import 'layouts/page';
@import 'layouts/auth-page';
