@import 'abstracts';

cpb-list-renderer {
  &:nth-child(1n) {
    .header,
    .cpb-filter-height-2 .p-datatable-thead cap-info-table-header > tr > th,
    .cpb-filter-height-3 .p-datatable-thead cap-info-table-header > tr > th {
      background: $gray-light;
    }

    .header {
      position: initial;
    }

    .header--sticky {
      position: unset;
    }

    div.date-filter .cap-calendar.p-calendar.cap-rounded input,
    div.date-filter .cap-calendar.p-calendar.cap-rounded input:hover,
    div.date-filter .cap-calendar.p-calendar.cap-rounded input:enabled:hover:not(.p-state-error) {
      height: 33px;
      outline: none;
      border-radius: 20px;
      padding-left: 35px;
    }

    .actions cap-button button {
      border-radius: 3px;
    }
  }
}
