@import 'abstracts';

div.p-inputswitch span.p-inputswitch-slider:before {
  margin-top: -10px;
}

button.cap-button--loading.cap-button {
  padding: 5px 10px;
  opacity: 1;
  background: rgba($primary, 0.3) !important; /* stylelint-disable-line declaration-no-important */
  border: 1px solid transparent;
  color: $white !important; /* stylelint-disable-line declaration-no-important */
  font-weight: 500;

  .p-button-icon {
    padding: 0;
  }

  .p-button-label {
    padding-left: 1em;
  }

  &:not(disabled):hover {
    background: rgba($primary, 0.3) !important; /* stylelint-disable-line declaration-no-important */
  }
}
