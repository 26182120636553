@import 'abstracts';

@mixin table-cell-border($color) {
  border-top: 1px solid $color;
  border-bottom: 1px solid $color;

  &:first-child {
    border-left: 1px solid $color;
  }

  &:last-child {
    border-right: 1px solid $color;
  }
}

body cap-info-table.cap-info--bordered .cap-info-table.p-datatable {
  .p-datatable-thead {
    @include media-breakpoint-between(md, lg) {
      display: table-header-group !important; /* stylelint-disable-line declaration-no-important */
    }

    th {
      height: auto;
      padding-top: 3px;
      padding-bottom: 6px;

      @include media-breakpoint-up(xl) {
        padding-bottom: 10px;
      }

      span.cap-info-table__column-header {
        font-size: 12px;
        font-weight: 600;
        color: $gray-light-tertiary;

        @include media-breakpoint-up(xl) {
          font-size: 13px;
        }

        &.p-sortable-column {
          display: flex;

          &[aria-sort='none'] {
            &:before {
              content: '\f0dc';
              font-family: 'Font Awesome 5 Pro';
              font-weight: 900;
              -webkit-font-smoothing: antialiased;
              display: inline-block;
              font-style: normal;
              font-variant: normal;
              text-rendering: auto;
              line-height: 1;
              margin-right: 5px;
            }

            cap-sort-icon {
              display: none;
            }
          }

          cap-sort-icon {
            float: left;
            margin-right: 5px;
          }

          &[aria-sort='ascending'] {
            .fas {
              &:before {
                content: '\f0de';
              }
            }

            cap-sort-icon {
              display: inline-block;
              order: -1;
            }
          }

          &[aria-sort='descending'] {
            .fas {
              &:before {
                content: '\f0dd';
              }
            }

            cap-sort-icon {
              display: inline-block;
              order: -1;
            }
          }
        }
      }
    }
  }

  .p-datatable-tbody {
    cap-info-table-row {
      tr.cap-info-table__row {
        @include media-breakpoint-between(md, lg) {
          box-shadow: none;
          display: table-row;
        }
        @include media-breakpoint-down(sm) {
          box-shadow: none;
          border-radius: 3px;
          border: 1px solid $munsell;
        }

        td.cap-info-table__column {
          font-size: 12px;
          @include table-cell-border($munsell);

          @include media-breakpoint-up(xl) {
            font-size: 14px;
          }

          .cap-info-table__mobile-column {
            @include media-breakpoint-down(sm) {
              font-size: 12px;

              .cap-info-table__mobile-header {
                color: $gray-dark;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}

body cap-info-table.cap-info--selectable .cap-info-table.p-datatable {
  .p-datatable-tbody {
    cap-info-table-row {
      tr.cap-info-table__row {
        @include media-breakpoint-down(sm) {
          position: relative;
          padding: 12px 12px 12px 30px;
        }

        td.cap-info-table__column {
          @include media-breakpoint-down(sm) {
            height: auto;
            padding-bottom: 6px;
          }

          &:first-child {
            @include media-breakpoint-down(sm) {
              position: absolute;
              left: 0;
              top: 50%;
              transform: translate3d(0, -50%, 0);
              border: 0;
              height: auto;
              width: 30px;

              cap-cell-data {
                width: 20px;

                .cap-info-table__mobile-column {
                  font-size: 12px;

                  .cap-info-table__mobile-header {
                    display: none;
                  }
                }
              }
            }
          }

          @include media-breakpoint-down(sm) {
            border: 0;

            &:first-child {
              border-left: 0;
            }

            &:last-child {
              border-right: 0;
            }
          }

          .cap-info-table__mobile-header {
            flex: 0 0 40%;
            max-width: 40%;
            color: $gray-dark;
            font-weight: 600;
          }

          .cap-info-table__mobile-cell {
            color: $body-color;
          }
        }
      }

      &.row--selected {
        tr {
          @include media-breakpoint-down(sm) {
            border: 1px solid $success;
          }

          td.cap-info-table__column {
            @include table-cell-border($success);

            @include media-breakpoint-down(sm) {
              border: 0;

              &:first-child {
                border-left: 0;
              }

              &:last-child {
                border-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

body cap-info-table.article-list-table .cap-info-table.p-datatable {
  .p-datatable-tbody {
    cap-info-table-row {
      tr.cap-info-table__row {
        @include media-breakpoint-down(sm) {
          padding: 0 15px 10px;
        }

        td.cap-info-table__column {
          @include media-breakpoint-down(sm) {
            border: 0;
            order: 1;
            height: auto;
            padding: 10px 0 0;
            font-size: 12px;
          }

          &:first-child,
          &:last-child {
            @include media-breakpoint-down(sm) {
              border: 0;
            }
          }

          .cap-info-table__mobile-column {
            font-size: 12px;
          }

          .cap-info-table__mobile-header {
            @include media-breakpoint-down(sm) {
              font-weight: normal;
              color: $gray-dark;
              flex: 0 0 40%;
              max-width: 40%;
            }
          }

          .cap-info-table__mobile-cell {
            @include media-breakpoint-down(sm) {
              color: $body-color;
            }
          }

          &.cap-info-table__column-ref_description { /* stylelint-disable-line selector-class-pattern */
            @include media-breakpoint-down(sm) {
              border-bottom: 1px solid $munsell;

              .cap-info-table__mobile-header {
                display: none;
              }

              .cap-info-table__mobile-cell {
                font-weight: 600;
                padding-bottom: 10px;
                color: $gray-dark;
                font-size: 14px;
              }
            }

            @include media-breakpoint-down(sm) {
              order: 0;
            }
          }

          cap-input {
            .p-inputgroup {
              input.p-inputtext {
                @include media-breakpoint-down(sm) {
                  height: 26px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.table-filters.cap-table-filter {
  @include media-breakpoint-down(lg) {
    padding-bottom: 20px;
  }

  .filters-label {
    @include media-breakpoint-down(lg) {
      padding-bottom: 15px;
    }
  }

  .filters-list > .row > * {
    @include media-breakpoint-down(lg) {
      margin-bottom: 10px;
    }

    cap-validator {
      display: none;
    }
  }
}
